/* Fontes  Acme, Open Sans, Roboto*/
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


/* Uso global para Evitar espaços */
*{
  padding: 0px;
  margin: 0px;
}


/* Configuração Da Barra de rolagem */
::-webkit-scrollbar-track {
  background-color: #F4F4F4;
}
::-webkit-scrollbar {
  width: 6px;
  background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
  background: #dad7d7;
}




body{
    background-color: var(--body) ;
}

/* Estilo  da div Main */
#app{
    max-height: 100vh;
    max-width: 100vw;
}

/* Define o scroll ativo caso necessario */
#app[scrollActive]{
    overflow: auto;
}


/* Estilos Globais */
.img-m{
    width: 60px;
    cursor: pointer;
}

.d-none{
    display: none !important;
}

.duvidas{
    color: var(--primaria);
    text-decoration: underline;
    font-family: 'Acme';
    font-size: 1.2rem;
    cursor: pointer;
}

.duvidas:hover{
    color: var(--secundaria);
}





/* Tags de uso global */
h1{
    font-family: 'Acme', sans-serif;
    color: var(--textColor);
    text-align: center;
    letter-spacing: 0.03rem;
}

h2{
    font-family: 'Roboto', sans-serif;
    color: var(--textColor);
}

span, ul{
    font-family: 'Segoe UI Regular';
    color: var(--textSecundaria);
}

button{
    background-color: var(--primaria);
    border: none;
    padding: 0.4rem 0.4rem 0.5rem 0.4rem;
    color: #fff;
    font-weight: bold;
    font-family: 'Open Sans';
    font-size: 0.9rem;
    letter-spacing: 0.04rem;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 6px 6px 10px #00000052;
    transition: all 0.3s ease;
}

button:hover{
    background-color: var(--secundaria);
}

input{
  width: -webkit-fill-available;
  height: 30px;
  border-radius: 5px;
  margin: 0.3rem 0;
  padding-left: 0.5rem;
  background-color: var(--input);
  color: var(--textInput);
  border: none;
  border-bottom: 2px solid var(--defaultUser);
  box-shadow: 2px 2px 4px #0000004a
}

input, select{
    border: 0 none;
    outline: 0;
}

p{
    font-size: 1rem;
    color: var(--textSecundaria);
    font-family: 'Roboto';
}

select{
    background-color: var(--input);
    box-shadow: 2px 2px 4px #0000004a;
    border: none;
    border-bottom: 2px solid var(--defaultUser);
    background-image: var(--icoSelect);
    color: var(--textInput);
    padding-left: 0.3rem;
    border-radius: 5px;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
}

select::placeholder{
    color: var(--placeInput);
}






/* Define as configuração do Swal */  
.swal2-popup.swal2-toast{
    background: var(--modal) !important;
}

.swal2-popup.swal2-modal {
    background: var(--modalSwal) !important;
}

.swal2-popup.swal2-toast h2, #swal2-html-container, #swal2-title{
    color: var(--letra) !important;
}

.swal2-html-container{
    font-family: 'Roboto';
}









/* Pooper  */
.popper {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 14px;
    background-color: #2b2d42bb;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    display: none;
    color: #fff;
    padding: 8px;
    z-index: 999999999;
}
.popper[data-show] {
    display: block;
}





/* modal pré definido Global */
.modal{
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    overflow: hidden;
}

.content-modal{
    margin: 5% auto;
    padding: 0 20px;
    width: 480px;
    height: fit-content;
    background: var(--modal);
    box-shadow: var(--modalSombra);
    backdrop-filter: blur( 13.5px );
    -webkit-backdrop-filter: blur( 13.5px );
    border-radius: 10px;
}

.modal-Close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
  
.modal-Close:hover, .modal-Close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}




/* Estilo da tela de loading */
.loading-loader{
    border: 16px solid #f3f3f3;
    border-top: 16px solid var(--primaria);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -60px;
    margin-top: -60px;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.loading-text {
    font-size: 2.5rem;
    position: absolute;
    top: 56%;
    left: calc(50% - 76px);
    font-family: Acme;
    color: #c5c5c5;
    animation: fadein 2s;
    animation: pulse 2s linear infinite;
}
@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes pulse {
    0% { color: #c5c5c5; }
    25% { color: #818181;}
    50% {color: #606060;}
    75% { color: #818181;}
    100% { color: #c5c5c5; }
}
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}






/* Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 25px;
    margin-right: 10px;
}
  
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--switch);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}
  
input:checked + .slider {
    background-color: var(--primaria);
}
  
input:focus + .slider {
    box-shadow: 0 0 1px var(--primaria);
}
  
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
  






/* Modal dos videos Tutoriais */
.tutorial{
    width: 60%;
    background: none;
    box-shadow: none;
    border: none;
    backdrop-filter: none;
}

.tutorial iframe{
  width: 100%;
  height: 450px;
  margin-top: 0.5rem;
  border-radius: 10px;
  border: none;
}

.tutorial span:hover{
  color: var(--primaria);
}


