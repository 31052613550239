/* Variaveis de cor Global */
:root{
    --body: #fff;
    --primaria:#1391cb;
    --secundaria: #075376;
    --terciaria:#91BEE5;
    --quarta:#D4EBF6;
    --textColor: #808080;
    --textSecundaria:#63707c;
    --btnNot: #c51313;
    --switch: #ccc;
    --letra:#000;
    --defaultUser:#dfe5e7;
    --user: #fff;
    --yellow: #fdfd027a;
    --input: #ddd;
    --textInput: #221f1f;
    --placeInput: #999;
    --modal:rgba(255, 255, 255, 0.81);
    --modalSombra: 0 8px 32px 0 rgb(31 38 135 / 37%);
    --modalSwal: #fff;
    --assinatura: #f4f4f4;
    --icoSelect: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    --capaFundo: contrast(0.5);
    --icoFilter: invert(65%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(89%) contrast(100%);
}

.dark{
    --body: #1c1c1c;
    --primaria: #0cbaba;
    --secundaria: #29abe2;
    --terciaria: #373f51;
    --quarta: #414b56;
    --textColor: #d9d9d9;
    --textSecundaria: #bdbbbb;
    --btnNot: #e74c3c;
    --switch:#b9b2b2;
    --letra: #fff;
    --defaultUser:#556080;
    --user: #E7ECED;
    --yellow: #cf9b00;
    --input: #2c3e50;
    --textInput: #fff;
    --placeInput: #aaa;
    --modal:rgb(78 76 76 / 81%);
    --modalSombra: 0 8px 32px 0 rgb(16 11 11 / 37%);
    --modalSwal: #3c3c3c;
    --assinatura: #333438;
    --icoSelect: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23f4f4f4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    --capaFundo: contrast(0);
    --icoFilter: invert(56%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(95%) contrast(100%);
}
